import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() {
  }

  public setError(title: string, message: any, toast: boolean, logout: boolean): void {


    if (message instanceof HttpErrorResponse) {
      if (message.status === 555) {
        // TODO call toast anyway?
        return;
      }
      console.log('%c ErrorService: %c' + title + ' - ' + message.message, 'background: #333333; color: #ff7777; padding: 5px 5px 5px 0px;', 'background: #333333; color: #00cc55; padding: 5px 5px 5px 0px;');
      return;
    }

    if (message instanceof Error) {
      console.log('%c ErrorService: %c' + title + ' - ' + JSON.stringify(message), 'background: #333333; color: #ff7777; padding: 5px 5px 5px 0px;', 'background: #333333; color: #00cc55; padding: 5px 5px 5px 0px;');
      return;
    }

    if (typeof message === 'string') {
      console.log('%c ErrorService: %c' + title + ' - ' + message, 'background: #333333; color: #ff7777; padding: 5px 5px 5px 0px;', 'background: #333333; color: #00cc55; padding: 5px 5px 5px 0px;');
      return;
    }

    console.warn(title);
    console.warn(message);
  }
}

import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {ApiService} from "./api.service";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
  private backgroundImage: string|null = null;
  private backgroundImageDefault = '/assets/images/backgrounds/default_light.png';
  private themeLink: HTMLLinkElement|null = null;
  private theme: 'light' | 'dark' = ((window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) ? 'dark' : 'light');
  private themeMap = {light: 'light-theme.css', dark: 'dark-theme.css'};

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService
  ) {
    this.setPreConnect(this.apiService.getUrl());
    this.setBackground(this.backgroundImage);
    this.setTheme(this.theme);
  }

  public setBackground(imageUrl: string|null): void {
    this.backgroundImage = imageUrl ? imageUrl : this.backgroundImageDefault;
    this.renderer.setStyle(this.document.body, 'background-image', 'url(' + this.backgroundImage + ')');
  }

  public getBackground(): string|null {
    return this.backgroundImage;
  }

  private setPreConnect(url: string): void {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'preconnect');
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.appendChild(this.document.head, link);

    // TODO: Remove after license purchased
    setTimeout(() => {
      const body = this.renderer.selectRootElement('body', true);
      if (body.lastChild) {
        this.renderer.removeChild(body, body.lastChild);
      }
    }, 2000);
  }



  // Method to load Syncfusion CSS into the <style> tag and set Bootstrap theme
  public setTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;

    if (this.themeLink) {
      this.renderer.setAttribute(this.themeLink, 'href', this.themeMap[theme]);
    } else {
      const themeLink: HTMLLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(themeLink, 'rel', 'stylesheet');
      this.renderer.setAttribute(themeLink, 'href', this.themeMap[theme]);

      // Find the 'styles.css' link element
      const stylesLink = this.document.querySelector('link[href="styles.css"]');

      if (stylesLink) {
        // Insert the new theme link before 'styles.css' so it comes after the theme
        this.renderer.insertBefore(this.document.head, themeLink, stylesLink);
      } else {
        // If 'styles.css' isn't found, append the new theme link at the end
        this.renderer.appendChild(this.document.head, themeLink);
      }

      // Set the reference for the theme link
      this.themeLink = themeLink;
    }

    // Set data-bs-theme attribute on the body tag for Bootstrap theme
    this.renderer.setAttribute(this.document.body, 'data-bs-theme', theme);
  }

  // Method to get the current theme
  public getTheme(): 'light' | 'dark' {
    return this.theme;
  }

  // Method to toggle between light and dark themes
  public toggleTheme(): void {
    this.setTheme(this.theme === 'light' ? 'dark' : 'light');
  }
}
